@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url("https://fonts.googleapis.com/css2?family=Circular:wght@700&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");
/* @import url("https://fonts.googleapis.com/css2?family=Circular+Pro:wght@700&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400&display=swap");

body {
  @apply leading-[normal] m-0;
  overflow-x: hidden;
  width: auto;
  margin: auto;
  padding: 10px;

}

*,
::before,
::after {
  border-width: 0;
}




input {
  touch-action: manipulation;

}


input:focus {
  outline: none;
}

textarea:focus {
  outline: none;
}

.custom-font {
  font-family: 'Inter', sans-serif;
}

@media screen and (max-width: 450px) {
  body {
    overflow-x: hidden;

  }
}

.custom-phone-input {
  /* background-color: red !important; */
  height: 500 !important;

}