.luupli {
    font-feature-settings: 'ss01' on;
    letter-spacing: -2.4px;
  }
  
  .frame_1171275224 {
    gap: 11px;
  }

  .active{
  color: black;
    
  }
  
